<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">3-4. 목욕&nbsp;급여제공기록</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width" content-class="round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <div class="mt-5 d-flex justify-center align-center"> 
            <expandNowDatePicker :prtDate="''" :lmtNxtDt="lmtNxtDt" @nowDate="getDate"></expandNowDatePicker>        
        </div>
        <v-row class="ma-0 pa-0 mt-3 mx-3">
            <v-col class="ma-0 pa-0" cols="7">
                <div class="d-flex justify-start align-center">
                    <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearch" />
                    <CmmLvnExpnBtn                        
                        v-model="isLvnStt"
                        class="ml-1"
                        @onList="lvnSttApply">
                    </CmmLvnExpnBtn>
                    <span class="d-inline-block ml-2" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                            hide-details single-line rounded outlined dense filled label="이름조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center ma-0 pa-0"   cols="5">
                <v-spacer></v-spacer>
                <span>
                    <v-btn
                        width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 19)" icon>
                        <v-icon size="20">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </span>
                <span class="ml-1 grey006--text fontOneRem">목욕시간설정</span>
                <span class="ml-3 black--text font-weight-medium">목욕방법</span>
                <span class="px-1">&#58;</span>
                <span class="d-inline-block" style="width: 110px;">
                    <v-select 
                        v-model="allBathMthdcd"                               
                        :items="vCd21"
                        item-text="valcdnm"
                        item-value="valcd"
                        dense hide-details outlined    
                        @change="onAllBathMthdcd"                            
                    /> 
                </span>
                <span class="pl-2 black--text font-weight-medium">작성자</span>
                <span class="px-1">&#58;</span>
                <span class="d-inline-block" style="width: 70px;">
                    <v-text-field
                        v-model="allWrtr" :key="forceRender"
                        color="blue" height="28" ref="info"
                        readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" 
                    small 
                    min-width="30"
                    height="26"  
                    style="padding:0px 1px 0px 2px !important"
                    @click="onModal(1,0)"
                    >
                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <div class="mt-2 mx-3 rounded-lg">
                <v-data-table class="msclnTable pt-1" height="658" locale="ko"
                    :headers="headers" 
                    :items="bnftrcrdList" 
                    :search="schDummy" 
                    :custom-filter="customFilter"
                    disable-pagination fixed-header hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>                           
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[6].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[7].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;">{{props.headers[8].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 70px !important;">{{props.headers[9].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT" style="width: 60px !important;">{{props.headers[10].text}}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>     
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.name}}</span>
                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <div class="d-flex justify-center align-center">
                                    <span class="cmmFontSizeOne d-inline-block">
                                        <span class="d-inline-block pt-1">
                                            <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                            <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                        </span>
                                    </span>
                                    <span class="mr-1">&#40;{{item.age}}&#41;</span>
                                </div>
                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.entStcd}}</span>
                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.lvnRmNm}}</span>
                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.actBathDt?.beforeDateFormatDot()}}</span>
                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <div class="d-flex justify-center">
                                    <div style="width:70px;">
                                        <CmmTimeField v-model="item.actBathTmStrt" :required="true"></CmmTimeField>
                                    </div>
                                </div>
                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">                            
                                <div class="d-flex justify-center">
                                    <div style="width:70px;">
                                        <CmmTimeField v-model="item.actBathTmEnd" :required="true"></CmmTimeField> 
                                    </div>
                                </div>
                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <span class="d-inline-block" style="width: 110px;">
                                    <v-select 
                                        v-model="item.bathMthdcd"                               
                                        :items="vCd21"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined 
                                        :rules="[rules.required]"                               
                                    /> 
                                </span>
                            </td>                        
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <div class="d-flex justify-center align-center">
                                    <span style="width:65px;margin-left:12px;">
                                        <v-text-field
                                            v-model="item.wrtr" :rules="[rules.required]" :key="forceRender"
                                            color="blue" height="28" readonly hide-details outlined dense>
                                        </v-text-field>
                                    </span>                                
                                    <v-btn 
                                        class="ml-1 brown lighten-3" 
                                        small 
                                        min-width="22"
                                        height="22"  
                                        style="padding:0px 1px 0px 2px !important"
                                        @click="onModal(2,item.bathSchdlPk)"
                                        >                                    
                                        <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                            </template>
                                            <span>직원선택</span>
                                        </v-tooltip>

                                    </v-btn>
                                </div>

                            </td>
                            <td :class="['tableBR tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;">
                                <div class="d-flex justify-center align-center">
                                    <v-btn icon color='blue200' @click="onSave(item.bathSchdlPk)">
                                        <v-icon>mdi-content-save-outline</v-icon>
                                    </v-btn>
                                    <!-- <v-btn icon color='grey006' @click="delRow(item.bathSchdlPk)">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn> -->
                                </div>
                            </td>
                            <td :class="['tableBB', (item.actBathDt == today)?'bathblnft-green-lighten-5':'']" style="height:40px !important; text-align: center;" >                            
                                <!-- <v-btn icon :color="item.color" @click="showDialog(item.bathSchdlPk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> -->
                                <span v-if="item.bnftOfrRecHisPk > 0" @click="showDialog(item.bathSchdlPk)">
                                    <v-btn icon @click="showDialog(item.bathSchdlPk)">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </span>
                                <span v-else>                                
                                    <v-btn
                                        @click="showDialog(item.bathSchdlPk)" 
                                        :color="item.color"
                                        outlined
                                        height="30"
                                    >
                                        미작성
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </template>
                </v-data-table> 
            </div>
        </v-form>
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[0]" persistent max-width="1200" min-width="1200">                
                <BathObsrvRcrd 
                    v-if="menu[0]"
                    ref="bathObsrvRcrd"
                    :inqYMD="inqYMD"
                    :sTime="bathObsrvRcrdSTime"
                    :eTime="bathObsrvRcrdETime"
                    :bnMmbrPk="bnMmbrPk"
                    :bathObsrvRcrdPk="bathObsrvRcrdPk" 
                    :bathMthdcd="BathObsrvRcrdCd"
                    :empWrtr="empWrtr" 
                    :empWrtrpk="empWrtrpk" 
                    @modalEvent="modalEvent"></BathObsrvRcrd>
            </v-dialog>
        </div> 
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'목욕 급여제공기록'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div> 
    </v-sheet>
</template>

<script>
import CmmTimeField from '../../components/commons/CmmTimeField.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import BathObsrvRcrd from '../../components/bnftrcrd/BathObsrvRcrd.vue';    //몸씻기
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { getMultiStcd } from '../../api/index.js';
import { selAllBathSchdlRecList, updBathSchdlRec, updMultiBathSchdlRec } from '../../api/bnftoffer.js';
import { selFcltyInitList  } from '../../api/opr.js';


export default {
    name: 'BathBlnftSrvRcrd',
        
    components: {
        CmmTimeField,
        expandNowDatePicker,
        exBtn,
        CmmLvnExpnBtn,
        EmpSelector,
        BathObsrvRcrd,
        btnModalDelete,
    },

    created: function(){
        this.lmtNxtDt = this.$moment().format('YYYY-MM-DD')
        this.getCode(['21','65'])
        this.getToday()
        this.filledBtn.forEach(() => {
            this.dialog.push(false)
        })
        this.outlinedBtn.forEach(() => {
            this.dialog.push(false)
        })        

        this.getFcltyInitList()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllBathSchdlRecList()
        })
    },

    computed: {

    },

    watch:{ 
        'startDay':function(){
            this.getAllBathSchdlRecList()
        }
    },
        
    methods: {
        onClickBtn:function(text){
            if(text == '일괄저장') this.onMultiSave()
        },
        getToday:function(){
            let date = new Date()
            this.today = date.getFullYear()+('00'+(date.getMonth()+1).toString()).slice(-2)+('00'+(date.getDate()).toString()).slice(-2)
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },       
        stCdMapping: function (res) {
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd21.splice(0)

            let obj = {
                valcd: "",
                valcdnm: "선택",
                valclcd: "",
                valclcdnm: "",
            }

            this.vCd21.push(obj)
            res.vCd21.forEach(code => {
                this.vCd21.push(code)
            });

            this.vCd65 = res.vCd65
        },
        getDate: function(value){
            this.startDay = value.date
            this.endDay = value.date
            this.prtWrtDt = value.date
        },
        getAllBathSchdlRecList: function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            selAllBathSchdlRecList(fcltyNum,this.startDay,this.endDay)
            .then((response) => ( this.getAllBathSchdlRecListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getAllBathSchdlRecListAfter:function(res){
            let me = this
            res.forEach(items => {
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
                items.isSelected = false
                if(items.bathMthdcd == null) items.bathMthdcd=''

                if(items.bnftOfrRecHisPk > 0) items.color='grey006' 
                else items.color='red'
                
                if(items.actBathTmHhStrt?.isEmptyVal() && items.actBathTmMmStrt?.isEmptyVal())
                    items.actBathTmStrt = items.actBathTmHhStrt + ':' + items.actBathTmMmStrt

                if(items.actBathTmHhEnd?.isEmptyVal() && items.actBathTmMmEnd?.isEmptyVal())
                    items.actBathTmEnd = items.actBathTmHhEnd + ':' + items.actBathTmMmEnd

            });

            this.bnftrcrdList = res

            this.$refs.form.resetValidation()
        },
        onSave: function(pk){
            let target = this.bnftrcrdList.filter(x=> x.bathSchdlPk == pk)

            if(target.length > 0){
                let chk = true
                
                if(!target[0].actBathTmStrt.isEmptyVal()){
                    this.snackControll([true, 2500, '시작시간을 확인해주세요.', 'warning'])
                    chk = false
                }

                if(!target[0].actBathTmEnd.isEmptyVal()){
                    this.snackControll([true, 2500, '종료시간을 확인해주세요.', 'warning'])
                    chk = false
                }

                if(target[0].bathMthdcd =='' || target[0].bathMthdcd == null){
                    this.snackControll([true, 2500, '목욕방법을 확인해주세요.', 'warning'])
                    chk = false
                }

                if(target[0].wrtr =='' || target[0].wrtr == null){
                    this.snackControll([true, 2500, '작성자를 확인해주세요.', 'warning'])
                    chk = false
                }

                if(chk && target[0].bathSchdlPk > 0){
                    let fcltyNum = this.$store.getters.getFcltyNum

                    let actBathTmStrt = target[0].actBathTmStrt.split(":")
                    let actBathTmEnd = target[0].actBathTmEnd.split(":")
                    
                    let obj = {
                        bathSchdlPk:target[0].bathSchdlPk,
                        fcltyNum:fcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        actBathDt:target[0].actBathDt,
                        bathMthdcd:target[0].bathMthdcd,
                        actBathTmHhStrt:actBathTmStrt[0],
                        actBathTmMmStrt:actBathTmStrt[1],
                        actBathTmHhEnd:actBathTmEnd[0],
                        actBathTmMmEnd:actBathTmEnd[1],
                        wrtr:target[0].wrtr,
                        wrtrMmbrPk:target[0].wrtrMmbrPk,
                    }
    
                    updBathSchdlRec(obj)
                        .then((response) => ( this.onSaveAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllBathSchdlRecList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }            
        },   
        onMultiSave: function(){
            if(this.$refs.form.validate()){
                this.getSelctList()   
                let obj ={
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    list:[]
                }

                this.bnftrcrdList.filter(x=>this.selctList.indexOf(x.bathSchdlPk) > -1).forEach(el => {
                    let actBathTmStrt = el.actBathTmStrt.split(":")
                    let actBathTmEnd = el.actBathTmEnd.split(":")

                    let itm = {
                        bathSchdlPk:el.bathSchdlPk,
                        actBathDt:el.actBathDt,
                        bathMthdcd:el.bathMthdcd,
                        actBathTmHhStrt:actBathTmStrt[0],
                        actBathTmMmStrt:actBathTmStrt[1],
                        actBathTmHhEnd:actBathTmEnd[0],
                        actBathTmMmEnd:actBathTmEnd[1],
                        wrtr:el.wrtr,
                        wrtrMmbrPk:el.wrtrMmbrPk,
                    }
                    obj.list.push(itm)
                })

                updMultiBathSchdlRec(obj)
                    .then((response) => ( this.onMultiSaveAfter(response.data)))
                    .catch((error) => console.log(error))

            }
        },
        onMultiSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllBathSchdlRecList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }            
        },  
        onDelete:function(res){
            if(res == 9990){
                if(this.bathSchdlPk > 0){
                    let fcltyNum = this.$store.getters.getFcltyNum
                    let obj = {
                        bathSchdlPk:this.bathSchdlPk,
                        fcltyNum:fcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        actBathDt:null,
                        bathMthdcd:'',
                        actBathTmHhStrt:'',
                        actBathTmMmStrt:'',
                        actBathTmHhEnd:'',
                        actBathTmMmEnd:'',
                        wrtr:'',
                        wrtrMmbrPk:0,
                    }

                    updBathSchdlRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log(error))
                }

            }else if(res == 7){
                this.dialogDelete = false
            }

        }, 
        onDeleteAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllBathSchdlRecList()
                this.bathSchdlPk = 0
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false            
        },      
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
             
            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;               
            }
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                col2 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) col2 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else col2 = col2 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }
            
           

            return col0 && col1 && col2;
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        checkBoxMessage: function () {
            if(this.allChecker == true){
                this.bnftrcrdList.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.bnftrcrdList.forEach(item => {
                    item.isSelected = false
                })
            }
        },
        checkBoxIndex: function () {            
            let chk = []

            this.bnftrcrdList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)
            });
            
            let result = chk.every((item) => item==true)
            if(result) this.allChecker = true
            else this.allChecker = false
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },        
        onModal: function (key,pk) {  
            this.key = key
            this.bathSchdlPk = pk
            this.menu.splice(1, 1, true)
        },
        getEmpInfo: function (obj) {   
            if(this.key == 1){
                this.allWrtr = obj[0].name
                this.getSelctList()   

                this.bnftrcrdList.filter(x=>this.selctList.indexOf(x.bathSchdlPk) > -1).forEach(el => {
                    el.wrtr = obj[0].name
                    el.wrtrMmbrPk = obj[0].mmbrPk
                });
            } else if(this.key == 2){
                let target = this.bnftrcrdList.find(x=> x.bathSchdlPk == this.bathSchdlPk)
                target.wrtr = obj[0].name
                target.wrtrMmbrPk = obj[0].mmbrPk
                this.bathSchdlPk = 0
            }
            this.key = 0
            ++this.forceRender
        },
        delRow(pk){
            this.bathSchdlPk = pk
            this.dialogDelete = true
        },
        onAllBathMthdcd:function(){
            this.getSelctList()            
            this.bnftrcrdList.filter(x=>this.selctList.indexOf(x.bathSchdlPk) > -1).forEach(el => {
                el.bathMthdcd = this.allBathMthdcd
            });
        },
        getSelctList:function(){            
            this.selctList.splice(0)
            this.bnftrcrdList.forEach(el => {
                let bl1 = true
                let bl2 = true
                let bl3 = true

                let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )

                if(lvnRunChc.length == 0)
                    bl1 = false
                else{
                    lvnRunChc.forEach((itm, i) => {
                        if(i == 0) bl1 = el.lvnRmInfPk == itm.lvnRmInfPk
                        else bl1 = bl1 || el.lvnRmInfPk == itm.lvnRmInfPk
                    });
                }


                if(this.schChkField.entStcd.length > 0){
                    if(this.schChkField.entStcd.indexOf(el.entStcd) == -1) bl2 = false
                }
                
                if(this.schField != ''){
                    if(!el.name.includes(this.schField)) bl3 = false
                }

                if(bl1 && bl2 && bl3) this.selctList.push(el.bathSchdlPk)
            })             
        },
        showDialog:function(pk){
            let target = this.bnftrcrdList.filter(x=> x.bathSchdlPk == pk)
            
            if(target.length > 0){
                let ymd = target[0].actBathDt
                let mmbrpk = target[0].bnMmbrPk
                let startHh =  target[0].actBathTmHhStrt
                let startMm =  target[0].actBathTmMmStrt
                let endHh =  target[0].actBathTmHhEnd
                let endMm =  target[0].actBathTmMmEnd
                let wrtr =  target[0].wrtr
                let wrtrMmbrPk =  target[0].wrtrMmbrPk
                let bathMthdcd = ''
                
                let startTime = ''
                let endTime = ''

                if(startHh != '' && startMm != '' && startHh != null && startMm != null){
                    startTime = startHh + ':' + startMm
                }

                if(endHh != '' && endMm != '' && endHh != null && endMm != null){
                    endTime = endHh + ':' + endMm
                }
                
                if(target[0].bathMthdcd != '' && target[0].bathMthdcd != null && target[0].bathMthdcd > 0){
                    bathMthdcd = target[0].bathMthdcd
                }
                
                if(ymd != '' & ymd != null && mmbrpk > 0){
                    this.bathObsrvRcrdPk = 1
                    this.inqYMD = ymd
                    this.bnMmbrPk = mmbrpk
                    this.empWrtr = wrtr
                    this.empWrtrpk = wrtrMmbrPk
                    this.BathObsrvRcrdCd = bathMthdcd
                    this.bathObsrvRcrdSTime = startTime
                    this.bathObsrvRcrdETime = endTime
                    // this.$refs.bathObsrvRcrd.onLoad(mmbrpk,ymd,wrtr,wrtrMmbrPk,startTime,endTime,bathMthdcd)

                    this.menu.splice(0, 1, true)
                }else{
                    this.snackControll([true, 2500, '목욕일을 확인해주세요.', 'warning'])
                }
            }
        },
        modalEvent: function (event) { 
            if(event == 99){                
                this.getAllBathSchdlRecList()
            }else{
                if(event == 0) {
                    this.bathObsrvRcrdPk = -1
                    this.BathObsrvRcrdCd = ''
                    this.inqYMD = ''
                    this.bathObsrvRcrdSTime = ''
                    this.bathObsrvRcrdETime = ''
                    this.bnMmbrPk = -1
                    this.empWrtr = ''
                    this.empWrtrpk = -1
                }
                this.menu.splice(event, 1, false)
            }      
        },  
        getFcltyInitList:function(){           
            selFcltyInitList(this.$store.getters.getFcltyNum, ['4'])
                .then((response) => ( this.getFcltyInitListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selFcltyInitList : ' + error))
        },
        getFcltyInitListAfter:function(res){
            let tday = this.$moment()
            let tommday = this.$moment().add(1, 'days')
            res.forEach(el => {
                if(el.itmVal1 == '1') this.lmtNxtDt = ''
                else {

                    let day     = this.$moment(tday.format('YYYYMMDD')+' '+el.itmVal2,'YYYYMMDD hh:mm')                    
                    let yn = this.$moment.duration(tday.diff(day)).asMinutes()                       
                    this.lmtNxtDt = yn > 0 ? tommday.format('YYYY-MM-DD') : tday.format('YYYY-MM-DD')
                }
            });
        },
         // 생활실 선택 컴포넌트 변경 이벤트
         lvnSttApply: function (arr){
            this.aslLvnStt = arr
            this.schDummy = String(Number(this.schDummy)+1)
        },
    },
    
    data: () => ({
        forceRender:0,
        key:0,
        today:'',
        bathSchdlPk:0,
        inqYMD:'',
        bnMmbrPk:-1,        
        empWrtr:'',
        empWrtrpk:-1,
        bathObsrvRcrdSTime:'',
        bathObsrvRcrdETime:'',
        BathObsrvRcrdCd:'',
        bathObsrvRcrdPk:-1,
        allChecker: false,
        dialog: [],
        vCd21: [],
        vCd65: [],
        lvnRmInf:[],
        menu: [false,false],
        schField: '',
        schDummy: '',
        startDay:'',
        endDay:'', 
        allWrtr:'',
        allBathMthdcd:'',
        bnftrcrdList: [],
        selctList:[],
        schChkField: { entStcd: [], lvnRmNm: [], target: '' },
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
        ],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '일괄저장', type: 'action', width:'800px', disabled: false, },
            // { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'800px', disabled: false, },
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '목욕급여기록 출력', width:'1000px', disabled: false, },
        ],
        headers: [
            { text: '수급자명', value: 'name', align: 'center'},
            { text: '성별(나이)', value: 'gndr', align: 'center'},
            { text: '현황', value: 'entStcd', align: 'center'},
            { text: '생활실', value: 'lvnRmNm', align: 'center'},
            { text: '목욕일', value: '', align: 'center'},
            { text: '시작시간', value: '', align: 'center'},
            { text: '종료시간', value: '', align: 'center'},
            { text: '목욕방법', value: '', align: 'center'},
            { text: '작성자', value: '', align: 'center'},
            { text: '처리', value: '', align: 'center'},
            { text: '기록', value: '', align: 'center'},
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
        prtWrtDt:'',
        lmtNxtDt:'',
        isLvnStt            : false,                            // 생활실 확장 활성화 여부
        aslLvnStt           : [],
    }),
};
</script>
<style>
.bathblnft-green-lighten-5{background-color: #E8F5E9 !important;}
</style>
<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">8-5. 월별 손익관리 &#40;청구기준&#41;</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>               
            </span>
            <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                </v-dialog>
            </span>
        </div>
        <div class="mt-5">
            <expandMonthPicker :prtDate="''" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </div>
        <div class="mt-2 px-2">
            <v-row no-gutters>
                <v-col cols="6" class="ma-0 py-0 pr-3">
                    <v-row  class="tableBT tableBB" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="5">                         
                            <span class="black--text font-weight-medium">{{$moment(this.yymm,'YYYYMM').format('YYYY.MM')}} 손익</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL"  cols="7">                        
                            {{prfLosTot?.toLocaleString('ko-KR')}}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0"></v-col>
            </v-row>

            <v-row class="mt-2" no-gutters>
                <v-col cols="6" class="ma-0 py-0 pr-3">
                    <v-row  class="tableBT tableBB" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="5">                         
                            <span class="black--text font-weight-medium">수익 합계</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL"  cols="7">                        
                            {{revTotL?.toLocaleString('ko-KR')}}      
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                    <v-row  class="tableBT tableBB" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="5">                         
                            <span class="black--text font-weight-medium">비용 합계</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL"  cols="7">                        
                            {{expTot?.toLocaleString('ko-KR')}}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="mt-2" no-gutters>
                <v-col cols="6" class="ma-0 py-0 pr-3">
                    <v-row  :class="['tableBT tableBB', revList.length >  14 ? 'mr-2__5' : '']" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">                         
                            <span class="black--text font-weight-medium">항목</span>
                            <span>
                                <v-btn 
                                    color="blue200" 
                                    height="26" 
                                    @click="onLosItm('1')"
                                    icon>
                                    <v-icon>mdi-playlist-plus</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  style="border-left: 1px solid #dbdbdb !important;" cols="6">                          
                            <span class="black--text font-weight-medium">금액</span>
                        </v-col>
                    </v-row>
                    <div v-if="revList.length>0" style="height:550px;overflow-y:auto">
                        <v-row v-for="(item, j) in revList" :key="j" class="tableBB" no-gutters>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="6"> 
                                <div style="width:85%">{{item.itmNm}}</div>
                                <div style="width:15%">                                     
                                    <v-btn v-if="item.dtlYn == '1'"  @click="onLosDtl(item)"
                                        class="" color="blue200" style="padding:0px 1px 0px 2px !important"
                                        min-width="40" height="28"
                                        icon>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="25" v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                            </template>
                                            <span>{{item.itmNm}} 손익항목상세관리</span>
                                        </v-tooltip>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="6"> 
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <span class="txtfldRT" v-if="item.autoCalcYn != '1'">
                                            <v-text-field color="blue" v-model="item.amt" type="number" height="28" @input="getPrlLosclclt" hide-details outlined dense :readonly="item.dtlYn == '1'"></v-text-field>
                                        </span>
                                        <div v-else style="width:100px;height:33px;"></div>
                                    </v-col>
                                    <v-col cols="6" class="pl-1 d-flex justify-end align-center">
                                        {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                    </v-col>
                                </v-row>   
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else>
                        <v-row  class="tableBB" no-gutters>
                            <v-col cols="12" class="d-flex justify-center align-center pa-1 ma-0">                                
                                <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                    <v-row  :class="['tableBT tableBB', expList.length >  14 ? 'mr-2__5' : '']" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">                         
                            <span class="black--text font-weight-medium">항목</span>
                            <span>
                                <v-btn 
                                    color="blue200" 
                                    height="26" 
                                    @click="onLosItm('2')"
                                    icon>
                                    <v-icon>mdi-playlist-plus</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  style="border-left: 1px solid #dbdbdb !important;" cols="6">                          
                            <span class="black--text font-weight-medium">금액</span>
                        </v-col>
                    </v-row>
                    <div v-if="expList.length>0" style="height:550px;overflow-y:auto">
                        <v-row v-for="(item, k) in expList" :key="k" class="tableBB" no-gutters>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="6"> 
                                <div style="width:85%">{{item.itmNm}}</div>
                                <div style="width:15%">                                     
                                    <v-btn v-if="item.dtlYn == '1'"  @click="onLosDtl(item)"
                                        class="" color="blue200" style="padding:0px 1px 0px 2px !important"
                                        min-width="40" height="28"
                                        icon>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="25" v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                            </template>
                                            <span>{{item.itmNm}} 손익항목상세관리</span>
                                        </v-tooltip>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="6"> 
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <span class="txtfldRT" v-if="item.autoCalcYn != '1'">
                                            <v-text-field color="blue" v-model="item.amt" type="number" height="28" @input="getPrlLosclclt" hide-details outlined dense :readonly="item.dtlYn == '1'"></v-text-field>
                                        </span>
                                        <div v-else style="width:100px;height:33px;"></div>
                                    </v-col>
                                    <v-col cols="6" class="pl-1 d-flex justify-end align-center">
                                        {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                    </v-col>
                                </v-row>   
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else>
                        <v-row  class="tableBB" no-gutters>
                            <v-col cols="12" class="d-flex justify-center align-center pa-1 ma-0">                                
                                <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" persistent max-width="1100" min-width="1100">                
                <mnthlPrlssLosItmWrt
                    v-if="dialog[0]"
                    :tabNo="tab" 
                    :calcClcd = "clcd"
                    @isClose="isModal(0)"
                    @modalEvent="modalEvent"
                ></mnthlPrlssLosItmWrt>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" persistent max-width="800" min-width="800">                
                <fcltyPrfLosDtlMdl
                    v-if="dialog[1]"
                    :yymm       = "yymm"
                    :itmCd      = "itmCd"
                    :itmNm      = "itmNm"
                    :stdClcd    = "stdClcd" 
                    :calcClcd   = "clcd"
                    @isClose="isModal(1)"
                    @modalEvent="modalEvent"
                    @setAmt="setDtlItmAmt" 
                ></fcltyPrfLosDtlMdl>
            </v-dialog>
        </div> 
    </v-sheet>
</template>
<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import mnthlPrlssLosItmWrt from '../../components/impExp/MnthlPrlssLosItmWrt.vue';
import fcltyPrfLosDtlMdl from '../../components/impExp/FcltyPrfLosDtlMdl.vue';

import { selFcltyPrfLosList, insMultiFcltyPrfLos } from '../../api/impExp.js';

export default {
    name: 'MnthlPrlssMgmt',
        
    components: {
        expandMonthPicker,
        mnthlPrlssLosItmWrt,
        fcltyPrfLosDtlMdl,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    watch:{
       
    },

    methods: {        
        chkLoding:function(){
            if(this.tab1Act == '1' && this.tab2Act == '1'){
                this.filledBtn[0].disabled  = false
                // this.outlinedBtn[0].disabled  = false
            }
        },
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onSave()               
                    break;
                default:
                    break;
            }
        },

        //손익관리저장
        onSave:function(){
            let obj = {
                list:[],
            }

            this.revList.forEach(item => {
                if(item.autoCalcYn != '1') {
                    item.fcltyNum = this.$store.getters.getFcltyNum
                    item.userNm = this.$store.getters.getUserNm
                    item.stdYymm = this.yymm
                    item.stdClcd = this.stdClcd
                    obj.list.push(item)
                }
            });

            this.expList.forEach(item => {
                if(item.autoCalcYn != '1') {
                    item.fcltyNum = this.$store.getters.getFcltyNum
                    item.userNm = this.$store.getters.getUserNm
                    item.stdYymm = this.yymm
                    item.stdClcd = this.stdClcd
                    obj.list.push(item)
                }
            });

            insMultiFcltyPrfLos(obj)
                .then((response) => ( this.onSaveAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/insMultiFcltyPrfLos : ' + error))
        },
        onSaveAfter:function(res){            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getFcltyPrfLosList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        //손익관리 조회
        getFcltyPrfLosList: function () {
            this.filledBtn[0].disabled  = true
            if(!this.dataCall){
                this.dataCall = true
                selFcltyPrfLosList(this.$store.getters.getFcltyNum, this.yymm, this.stdClcd)
                    .then((response) => ( this.getFcltyPrfLosListAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/selFcltyPrfLosList : ' + error))
            }
        },
        getFcltyPrfLosListAfter: function (res) {
            this.filledBtn[0].disabled  = false
            this.dataCall = false
            this.revList.splice(0)
            this.expList.splice(0)

            res.forEach(item => {
                if(item.amt == '' || item.amt == null) item.amt = 0 

                switch (item.calcClcd) {
                    case '1':
                        this.revList.push(item)
                        break;
                    case '2':
                        this.expList.push(item)
                        break;
                    default:
                        break;
                }
            });

            this.getPrlLosclclt()
        },

        //계산
        getPrlLosclclt:function(){
            this.prfLosTot = 0
            this.revTotL = 0
            this.expTot = 0

            this.revList.forEach(item => {
                this.revTotL += Number(item.amt)
            });

            this.expList.forEach(item => {
                this.expTot += Number(item.amt)
            });

            this.prfLosTot = Number(this.revTotL)-Number(this.expTot)
        },

        getPickerDate: function (date) {
            this.filledBtn[0].disabled  = true
            // this.outlinedBtn[0].disabled  = true
            this.yymm = date
            this.getFcltyPrfLosList()
        },        
        snackControll: function (options) {
            this.snackOptions = options
        },
        
        onModal:function(key){
            this.dialog.splice(key, 1, true)
        },
        isModal:function(key){
            this.dialog.splice(key, 1, false)
        },
        modalEvent:function(key){
            if(key == 99){
                this.$refs.mnthlPrlssTab1.onLoad()
                this.$refs.mnthlPrlssTab2.onLoad()
            }
        },
        setDtlItmAmt:function(obj){
            if(obj.calcClcd == '1'){
                let idx = this.revList.findIndex(v=>v.itmCd == obj.itmCd)
                this.revList[idx].amt = obj.amt
            }else if(obj.calcClcd == '2'){
                let idx = this.expList.findIndex(v=>v.itmCd == obj.itmCd)
                this.expList[idx].amt = obj.amt
            }

            this.getPrlLosclclt()
        },

        onLosItm:function(clcd){
            this.onModal(0)
            this.clcd = clcd            
        },

        onLosDtl:function(target){            
            this.onModal(1)
            this.clcd       = target.calcClcd
            this.stdClcd    = '1'
            this.itmCd      = target.itmCd
            this.itmNm      = target.itmNm
        },
    },
    data: () => ({   
        dataCall    : false,
        tab         : 0,   
        yymm        : '',
        stdClcd     : '1',
        clcd        : '',
        itmCd       : '',
        itmNm       : '',
        prfLosTot   : 0,
        revTotL     : 0,
        revList     : [],
        expTot      : 0,
        expList     : [],
        dialog:[false,false,], 
        tabItems: [
            { index: 0, tab: '청구기준', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '입출금기준', class: 'fontOneDotTwoRem px-15', enter:false},
        ],         
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    })
}
</script>
<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">보호자 정보 수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="offMdl" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-8 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">주 보호자 정보</span>
            <v-spacer></v-spacer>
            <span class="d-inline-block rounded white" style="width: 220px;">
                <v-select
                    v-model="sltBnfcrPk"
                    :items="bnfcrPkList" 
                    item-text="viewTxt" item-value="bnfcrPk"
                    dense hide-details outlined>
                </v-select>
            </span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-medium mr-1 posn-rltv">
                    보호자명
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -16px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="tableBR pa-1" cols="2">
                <v-text-field
                    v-model="galFmlyInp.name" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-medium mr-1 posn-rltv">
                    관계
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -16px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="tableBR pa-1" cols="2">
                <v-text-field
                    v-model="galFmlyInp.rltns" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="font-weight-medium">전화번호</span>
            </v-col>
            <v-col class="pa-1" cols="5">
                <span class="d-inline-block" style="width: 58px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.tel_1" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
                <span class="mx-1">
                    <v-icon size="16">mdi-minus</v-icon>
                </span>
                <span class="d-inline-block" style="width: 66px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.tel_2" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
                <span class="mx-1">
                    <v-icon size="16">mdi-minus</v-icon>
                </span>
                <span class="d-inline-block" style="width: 66px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.tel_3" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="font-weight-medium">청구서수신</span>
            </v-col>
            <v-col class="tableBR pa-1" cols="2">
                <v-select
                    v-model="galFmlyInp.msClcd"
                    :items="billRcvCd" @change="aplctSavBtn"
                    item-text="valcdnm" item-value="valcd"
                    dense hide-details outlined>
                </v-select>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-medium mr-1 posn-rltv">
                    생년월일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -16px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="tableBR pa-1" cols="2">
                <CmmDateComp
                    v-model="galFmlyInp.rsdntBirth"
                    :required="true" @input="aplctSavBtn">
                </CmmDateComp>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                    휴대폰
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -16px;">
                        <v-icon
                            v-if="galFmlyInp.msClcd == '1' || galFmlyInp.msClcd == '2'"
                            color="red">
                            mdi-star-four-points-small
                        </v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="pa-1" cols="5">
                <span class="d-inline-block" style="width: 58px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.celphn_1" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
                <span class="mx-1">
                    <v-icon class="mb-1" size="16">mdi-minus</v-icon>
                </span>
                <span class="d-inline-block" style="width: 66px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.celphn_2" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
                <span class="mx-1">
                    <v-icon class="mb-1" size="16">mdi-minus</v-icon>
                </span>
                <span class="d-inline-block mr-3" style="width: 66px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.celphn_3" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="white--text mr-1"
                    style="padding: 9px 7px 8px 5px !important; margin-bottom: 3px;"
                    :color="galFmlyInp.isPay ? 'blueBtnColor' : 'greyC00'"
                    @click="galFmlyInp.isPay = !galFmlyInp.isPay"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">급여</span>
                </v-btn>
                <v-btn
                    class="white--text"
                    style="padding: 9px 7px 8px 5px !important; margin-bottom: 3px;"
                    :color="galFmlyInp.isBill ? 'blueBtnColor' : 'greyC00'"
                    @click="galFmlyInp.isBill = !galFmlyInp.isBill"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">청구</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                    이메일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -16px;">
                        <v-icon
                            v-if="galFmlyInp.msClcd == '3'"
                            color="red">
                            mdi-star-four-points-small
                        </v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="pa-1" cols="11">
                <span class="d-inline-block" style="width: 148px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.emailId" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-icon class="mx-2" style="padding-top: 1px;" size="20" color="greyE00">mdi-at</v-icon>
                <span class="d-inline-block" style="width: 148px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.emailDmn"
                        @input="aplctSavBtn" :disabled="galFmlyInp.emailDmnClcd != '0'"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
                <span class="d-inline-block rounded white ml-1" style="width: 148px;">
                    <v-select
                        v-model="galFmlyInp.emailDmnClcd"
                        :items="vCd64"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                <span class="fontOneDotOneRem font-weight-medium mr-1 posn-rltv">
                    주소
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -16px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center pa-1" cols="11">
                <span class="d-inline-block" style="width: 70px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.zip" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined readonly dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="white--text ml-1 mr-2" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                    min-width="30" height="26" @click="onLclMdl('01', 600)" small>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                        </template>
                        <span>주소검색</span>
                    </v-tooltip>
                </v-btn>
                <span class="d-inline-block mr-1" style="width: 260px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.addr" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined readonly dense>
                    </v-text-field>
                </span>
                <span class="d-inline-block" style="width: 320px; height: 100%;">
                    <v-text-field
                        v-model="galFmlyInp.addrDtl" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mb-4"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
        <v-row class="pa-0 mt-4 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">가족 정보</span>
        </v-row>
        <v-divider :class="[fmlyInpList.length > 3 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <v-row :class="[fmlyInpList.length > 3 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                <span class="font-weight-medium">순번</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">이름</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                <span class="font-weight-medium">관계</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="4">
                <span class="font-weight-medium">휴대폰</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">수신여부</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="1">
                <v-btn
                    class="mr-1" color="blue200" height="26"
                    @click="addRow()"
                    icon>
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider :class="[fmlyInpList.length > 3 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="fmlyInpList.length > 0" class="overflow-y-auto" style="width: 100%; height: 120px;">
            <v-row
                :class="['tableBB', fmlyInpList.length > 3 ? '' : 'mr-1']"
                v-for="(itm, i) in fmlyInpList" :key="i"
                no-gutters>
                <v-col class="txtAlgnMid tableBR pa-1" style="line-height: 2;" cols="1">
                    <v-text-field
                        v-model="itm.fmlyOrd"
                        class="" height="28" color="blue" type="number"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="d-flex align-center txtAlgnMid tableBR pa-1" cols="3">
                    <span class="d-inline-block mr-1" style="width: 190px; height: 100%;">
                        <v-text-field
                            v-model="itm.name"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-select
                        v-model="itm.msClcd"
                        :items="billRcvCd.filter( v => v.valcd != '3' )"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </v-col>
                <v-col class="txtAlgnMid tableBR pa-1" cols="1">
                    <v-text-field
                        v-model="itm.rltns"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="4">
                    <span class="d-inline-block" style="width: 58px; height: 100%;">
                        <v-text-field
                            v-model="itm.celphn_1"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon class="mb-1" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 66px; height: 100%;">
                        <v-text-field
                            v-model="itm.celphn_2"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon class="mb-1" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 66px; height: 100%;">
                        <v-text-field
                            v-model="itm.celphn_3"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 8px;" cols="2">
                    <v-btn
                        class="white--text mr-1"
                        style="padding: 9px 7px 8px 5px !important; margin-bottom: 3px;"
                        :color="itm.isPay ? 'blueBtnColor' : 'greyC00'"
                        @click="rflFmlyChc('pay', i)"
                        min-width="20" height="20" small outlined>
                        <span class="fontNineThRem" style="padding-bottom: 2px;">급여</span>
                    </v-btn>
                    <v-btn
                        class="white--text"
                        style="padding: 9px 7px 8px 5px !important; margin-bottom: 3px;"
                        :color="itm.isBill ? 'blueBtnColor' : 'greyC00'"
                        @click="rflFmlyChc('bill', i)"
                        min-width="20" height="20" small outlined>
                        <span class="fontNineThRem" style="padding-bottom: 2px;">청구</span>
                    </v-btn>
                </v-col>
                <v-col class="txtAlgnMid py-1" cols="1">
                    <span class="black--text font-weight-medium">
                        <v-btn
                            class="" color="blueBtnColor" height="26"
                            :disabled="itm.name == '' || itm.name == null"
                            @click="dcsnFmlySavBtn(itm, i)"
                            icon>
                            <v-icon>mdi-content-save-edit-outline</v-icon>
                        </v-btn>
                    </span>
                    <span class="black--text font-weight-medium">
                        <v-btn
                            class="" color="grey006" height="26"
                            @click="rmFmlyRow(i)"
                            icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <AdrsSrch v-if="lclMdlInf.typ == '01'" @onDataThr="rflAddrInf" @isClose="rstLclMdlInf"></AdrsSrch>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';
import AdrsSrch from '../../commons/AdrsSrch.vue';

export default {
    name: 'FmlyInfSavMdl',
    
    components: {
        CmmDateComp,
        AdrsSrch,
    },

    props : { 
        prtCd64: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.vCd64 = [...this.prtCd64]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })
      
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)

            if(this.bscsInf.bnMmbrPk > -1)
                this.getPksList(this.bscsInf.bnMmbrPk)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '1'){
                this.$parent.$parent.$parent.$parent.mdlOff()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                
                this.runAble = '0'
            }
        },
        'galFmlyInp.emailDmnClcd': function (){
            if(this.galFmlyInp.emailDmnClcd == '0')
                this.galFmlyInp.emailDmn = ''
            else{
                let emlChc = this.prtCd64.findIndex( v => v.valcd == this.galFmlyInp.emailDmnClcd )
                if(emlChc > -1)
                    this.galFmlyInp.emailDmn = this.prtCd64[emlChc].valcdnm
                else
                    this.galFmlyInp.emailDmn = ''

                this.aplctSavBtn()
            }
        },
        'sltBnfcrPk': function(v){
            if(this.sltBnfcrPk > -1){
                this.getFmlyInf(this.bscsInf.bnMmbrPk, v)
                this.getFmlyList(this.bscsInf.bnMmbrPk, v)
            }
        },
        'prtCd64': function(v){
            this.vCd64.splice(0)
            this.vCd64 = [...v]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })
        },
    },
        
    methods: {
        // 수급자 구성원(bnfcrPk) 고유번호 조회
        getPksList: function (mbr){
            let obj = {}
            obj.bnMmbrPk = mbr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrPkList', obj)
                .then((response) => ( this.pksRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrPkList : ' + error))
        },

        pksRst: function (res){
            if(res.statusCode == '200'){
                if(Array.isArray(res.result)){
                    res.result.forEach((itm, i) => {
                        let obj = ''
                        if(i == 0)
                            obj += '현재 '
                        else
                            obj += '과거 '

                        if(itm.lvDt == null || itm.lvDt == '')
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ~ )')
                        else{
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ')
                            obj += this.$moment(itm.lvDt, 'YYYYMMDD').format('~ YY.MM.DD ) ')
                        }

                        itm.viewTxt = obj
                    });
                    
                    let arr = res.result.filter( v => v.entDt.slice(0, 4) == this.prtYear )
                    if(arr.length > 0)
                        this.sltBnfcrPk = arr[0].bnfcrPk
                    else
                        this.sltBnfcrPk = res.result[0].bnfcrPk

                    this.bnfcrPkList = res.result
                }
            }
            else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message                     // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 주 보호자 기본정보 조회
        getFmlyInf: function (mbr, fcr){
            this.galFmlyInf = Object.assign({})

            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrFmlyInfo', obj)
                .then((response) => ( this.fmlyInfRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrFmlyInfo : ' + error))
        },

        fmlyInfRst: function (res){
            if(Object.keys(res.result.galFmly).length > 0){
                this.galFmlyInf = res.result.galFmly
                this.rflGalFmly()
            }

            this.aplctSavBtn()
        },

        // 부 보호자 기본정보 조회
        getFmlyList: function (mbr, fcr){
            this.fmlyList.splice(0)

            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selFmlyList', obj)
                .then((response) => ( this.fmlyListRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selFmlyList : ' + error))
        },

        fmlyListRst: function (res){
            if(res.result.fmlyList.length > 0){
                //fmlyOrd
                res.result.fmlyList.forEach(itm => {
                    itm.fmlyOrd = Number(itm.fmlyOrd) - 1
                });
                this.fmlyList = res.result.fmlyList
                this.rflFmly()
            }

            this.aplctSavBtn()
        },

        // 주 보호자 수정
        updFmlyInf: function (gal){
            let obj = {}
            obj.bnMmbrPk = this.bscsInf.bnMmbrPk
            obj.bnfcrPk = this.sltBnfcrPk
            obj.galFmlyInfo = gal
            //obj.fcltyNum = this.$store.getters.getFcltyNum
  
            http.post('bnfcrAuth/updBnfcrFmlyInfo', obj)
                .then((response) => ( this.updFmlyRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrFmlyInfo : ' + error))
        },

        updFmlyRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.savBtn = false
                this.getFmlyInf(this.bscsInf.bnMmbrPk, this.sltBnfcrPk)
            }
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 가족 수정
        insFmlyInf: function (obj){
            http.post('bnfcrAuth/insFmly', obj)
                .then((response) => ( this.fmlyRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insFmly : ' + error))
        },

        fmlyRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.savBtn = false
                this.getFmlyList(this.bscsInf.bnMmbrPk, this.sltBnfcrPk)
            }
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 가족 삭제
        rmFmlyInf: function (obj){
            http.post('bnfcrAuth/delFmly', obj)
                .then((response) => ( this.fmlyRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/delFmly : ' + error))
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
        },

        // 주 보호자 정보 적용
        rflGalFmly: function () {
            this.galFmlyInp.name = this.galFmlyInf.name
            this.galFmlyInp.rltns = this.galFmlyInf.rltns
            if(this.galFmlyInf.msClcd == null || this.galFmlyInf.msClcd == '')
                this.galFmlyInp.msClcd = '0'
            else
                this.galFmlyInp.msClcd = this.galFmlyInf.msClcd
            this.galFmlyInp.rsdntBirth = this.$moment(this.galFmlyInf.rsdntBirth, 'YYYYMMDD').format('YYYY-MM-DD')
            this.galFmlyInp.emailId = this.galFmlyInf.emailId
            this.galFmlyInp.emailDmn = this.galFmlyInf.emailDmn
            this.galFmlyInp.zip = this.galFmlyInf.zip
            this.galFmlyInp.addr = this.galFmlyInf.addr
            this.galFmlyInp.addrDtl = this.galFmlyInf.addrDtl
            this.galFmlyInp.tel_1 = this.galFmlyInf.tel_1
            this.galFmlyInp.tel_2 = this.galFmlyInf.tel_2
            this.galFmlyInp.tel_3 = this.galFmlyInf.tel_3
            this.galFmlyInp.celphn_1 = this.galFmlyInf.celphn_1
            this.galFmlyInp.celphn_2 = this.galFmlyInf.celphn_2
            this.galFmlyInp.celphn_3 = this.galFmlyInf.celphn_3

            let chc = this.vCd64.findIndex( v => v.valcdnm == this.galFmlyInf.emailDmn )
            if(chc > -1)
                this.galFmlyInp.emailDmnClcd = this.vCd64[chc].valcd
            else
                this.galFmlyInp.emailDmnClcd = '0'

            this.galFmlyInf.isPay = false
            this.galFmlyInf.isBill = false
            if(Array.isArray(this.galFmlyInf.cellRcvYn))
                this.galFmlyInf.cellRcvYn.forEach(itm => {
                    switch (itm) {
                        case '1':
                            this.galFmlyInp.isPay = true
                            this.galFmlyInf.isPay = true
                            break;
                        case '2':
                            this.galFmlyInp.isBill = true
                            this.galFmlyInf.isBill = true
                            break;
                        default:
                            break;
                    }
                });

            this.aplctSavBtn()
        },

        // 가족 정보 적용
        rflFmly: function () {
            if(this.fmlyList.length > 0){
                this.fmlyList.forEach(itm => {
                    itm.isPay = false
                    itm.isBill = false
                    if(Array.isArray(itm.cellRcvYn))
                        itm.cellRcvYn.forEach(idx => {
                            switch (idx) {
                                case '1':
                                    itm.isPay = true
                                    break;
                                case '2':
                                    itm.isBill = true
                                    break;
                                default:
                                    break;
                            }
                        });

                    if(itm.msClcd == null || itm.msClcd == '')
                        itm.msClcd = '0'
                });
             
                this.fmlyInpList = this.deepCopy(this.fmlyList)
            }

            this.aplctSavBtn()
        },

        // 주 보호자 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.galFmlyInp.rsdntBirth) == false)
                return 0
            if(this.galFmlyInp.name == '')
                return 0
            if(this.galFmlyInp.rltns == '')
                return 0
            if(this.galFmlyInp.zip == '' || this.galFmlyInp.addr == '')
                return 0
            if(this.galFmlyInp.msClcd != '0'){
                switch (this.galFmlyInp.msClcd) {
                    case '1':
                    case '2':
                        if(this.galFmlyInp.celphn_1 == '' || this.galFmlyInp.celphn_2 == '' || this.galFmlyInp.celphn_3 == '')
                            return 0
                        break;
                    case '3':
                        if(this.galFmlyInp.emailId == '' || this.galFmlyInp.emailDmn == '')
                            return 0
                        break;
                    default:
                        break;
                }
            }

            this.savBtn = false
        },

        // 주 보호자 저장버튼 작동
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                                 // 경고 노출여부
            obj.tmr = 3000                                  // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                               // 경고 유형 (success, info, warning, error)
            obj.title = '수정된 항목이 존재하지 않습니다.'     // 경고 타이틀
            obj.cntnt = []                                  // 경고 내용 (arr만 출력됨!)

            if(this.chngGalInpChc() == 0){
                obj.cntnt.push('기존과 같은 정보로는 저장이 불가합니다.')
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let galFmlyInf = Object.assign({}, this.galFmlyInp)
            galFmlyInf.mmbrPk = this.galFmlyInf.mmbrPk

            galFmlyInf.cellRcvYn = []
            if(galFmlyInf.isPay)
                galFmlyInf.cellRcvYn.push('1')
            if(galFmlyInf.isBill)
                galFmlyInf.cellRcvYn.push('2')

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.galFmlyInp.rsdntBirth))
                galFmlyInf.rsdntBirth = this.$moment(galFmlyInf.rsdntBirth, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                galFmlyInf.rsdntBirth = ''

            if(galFmlyInf.rsdntBirth != '')
                this.updFmlyInf(galFmlyInf)
            else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = '필수항목을 입력해주세요.'        // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 가족 저장버튼 작동
        dcsnFmlySavBtn: function (itm, idx) {
            let obj = {}
            obj.actv = true                                   // 경고 노출여부
            obj.tmr = 2500                                    // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                              // 경고 유형 (success, info, warning, error)
            obj.title = '입력 항목을 다시 확인해주세요.'        // 경고 타이틀
            obj.cntnt = []                                   // 경고 내용 (arr만 출력됨!)
            
            if(this.chngFmlyRowInpChc(idx) == 0){
                obj.cntnt.push('기존과 동일한 정보로는 저장이 불가합니다.')
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let fmlyInf = Object.assign({}, itm)
            fmlyInf.bnMmbrPk = this.bscsInf.bnMmbrPk
            fmlyInf.bnfcrPk = this.sltBnfcrPk
            fmlyInf.fmlyOrd = Number(fmlyInf.fmlyOrd) + 1
            fmlyInf.cellRcvYn = []
          
            if(Number(fmlyInf.fmlyOrd) < 2){
                obj.cntnt.push('순번을 1이상 정수로 입력해주세요.')
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            if(fmlyInf.isPay)
                fmlyInf.cellRcvYn.push('1')
            if(fmlyInf.isBill)
                fmlyInf.cellRcvYn.push('2')
 
            if(fmlyInf.name != '')
                this.insFmlyInf(fmlyInf)
            else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = '필수항목을 입력해주세요.'        // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 주 보호자 입력항목 변경여부 확인
        chngGalInpChc: function () {
            let cnt = 0

            if(this.$moment(this.galFmlyInf.rsdntBirth, 'YYYYMMDD').format('YYYY-MM-DD') == this.galFmlyInp.rsdntBirth)
                cnt += 1
            if(this.galFmlyInp.name == this.galFmlyInf.name)
                cnt += 1
            if(this.galFmlyInp.rltns == this.galFmlyInf.rltns)
                cnt += 1
            if(this.galFmlyInp.msClcd == this.galFmlyInf.msClcd)
                cnt += 1
            if(this.galFmlyInp.emailId == this.galFmlyInf.emailId)
                cnt += 1
            if(this.galFmlyInp.emailDmn == this.galFmlyInf.emailDmn)
                cnt += 1
            if(this.galFmlyInp.zip == this.galFmlyInf.zip)
                cnt += 1
            if(this.galFmlyInp.addr == this.galFmlyInf.addr)
                cnt += 1
            if(this.galFmlyInp.addrDtl == this.galFmlyInf.addrDtl)
                cnt += 1
            if(this.galFmlyInp.tel_1 == this.galFmlyInf.tel_1)
                cnt += 1
            if(this.galFmlyInp.tel_2 == this.galFmlyInf.tel_2)
                cnt += 1
            if(this.galFmlyInp.tel_3 == this.galFmlyInf.tel_3)
                cnt += 1
            if(this.galFmlyInp.celphn_1 == this.galFmlyInf.celphn_1)
                cnt += 1
            if(this.galFmlyInp.celphn_2 == this.galFmlyInf.celphn_2)
                cnt += 1
            if(this.galFmlyInp.celphn_3 == this.galFmlyInf.celphn_3)
                cnt += 1
            if(this.galFmlyInp.isPay == this.galFmlyInf.isPay)
                cnt += 1
            if(this.galFmlyInp.isBill == this.galFmlyInf.isBill)
                cnt += 1
         
            if(cnt == 17)
                return 0
            else
                return 1
        },

        // 가족목록 입력항목 변경여부 확인
        chngFmlyInpChc: function () {
            let cnt = 0

            this.fmlyList.forEach((itm, i) => {
                let lclCnt = 0

                if(itm.fmlyOrd == this.fmlyInpList[i].fmlyOrd)
                    lclCnt += 1
                if(itm.name == this.fmlyInpList[i].name)
                    lclCnt += 1
                if(itm.msClcd == this.fmlyInpList[i].msClcd)
                    lclCnt += 1
                if(itm.rltns == this.fmlyInpList[i].rltns)
                    lclCnt += 1
                if(itm.celphn_1 == this.fmlyInpList[i].celphn_1)
                    lclCnt += 1
                if(itm.celphn_2 == this.fmlyInpList[i].celphn_2)
                    lclCnt += 1
                if(itm.celphn_3 == this.fmlyInpList[i].celphn_3)
                    lclCnt += 1
                if(itm.isPay == this.fmlyInpList[i].isPay)
                    lclCnt += 1
                if(itm.isBill == this.fmlyInpList[i].isBill)
                    lclCnt += 1

                if(lclCnt == 9)
                    cnt += 1
            });

            if(cnt == this.fmlyList.length && this.fmlyInpList.length == this.fmlyList.length)
                return 0
            else
                return 1
        },

        // 가족 입력항목 변경여부 확인
        chngFmlyRowInpChc: function (idx) {
            let cnt = 0

            if(this.fmlyInpList[idx].mmbrPk != -1){
                if(this.fmlyList[idx].fmlyOrd == this.fmlyInpList[idx].fmlyOrd)
                    cnt += 1
                if(this.fmlyList[idx].name == this.fmlyInpList[idx].name)
                    cnt += 1
                if(this.fmlyList[idx].msClcd == this.fmlyInpList[idx].msClcd)
                    cnt += 1
                if(this.fmlyList[idx].rltns == this.fmlyInpList[idx].rltns)
                    cnt += 1
                if(this.fmlyList[idx].celphn_1 == this.fmlyInpList[idx].celphn_1)
                    cnt += 1
                if(this.fmlyList[idx].celphn_2 == this.fmlyInpList[idx].celphn_2)
                    cnt += 1
                if(this.fmlyList[idx].celphn_3 == this.fmlyInpList[idx].celphn_3)
                    cnt += 1
                if(this.fmlyList[idx].isPay == this.fmlyInpList[idx].isPay)
                    cnt += 1
                if(this.fmlyList[idx].isBill == this.fmlyInpList[idx].isBill)
                    cnt += 1
            }
            else{
                cnt += 3
                if(this.fmlyInpList[idx].name == '')
                    cnt += 1
                if(this.fmlyInpList[idx].msClcd == '0')
                    cnt += 1
                if(this.fmlyInpList[idx].rltns == '')
                    cnt += 1
                if(this.fmlyInpList[idx].celphn_1 == '')
                    cnt += 1
                if(this.fmlyInpList[idx].celphn_2 == '')
                    cnt += 1
                if(this.fmlyInpList[idx].celphn_3 == '')
                    cnt += 1
            }

            if(cnt == 9)
                return 0
            else
                return 1
        },

        // 팝업 닫기/확인
        offMdl: function () {
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '저장되지 않은 작성항목이 존재합니다.'
            obj.cntnt = ['저장하지 않은 항목은 손실됩니다. 창을 닫을까요?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 창을 닫겠습니다.'

            if(this.chngGalInpChc() == 0 && this.chngFmlyInpChc() == 0)
                this.$parent.$parent.$parent.$parent.mdlOff()
            else{
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });
                this.runAble = '1'
            }
        },

        // 가족 정보 목록 추가
        addRow: function () {
            let max = 0
            if(this.fmlyInpList.length > 0)
                max = this.fmlyInpList.reduce((acc, cur) => (acc > cur.fmlyOrd ? acc : cur.fmlyOrd), 0)

            let obj = {}
            obj.contactPk = -1
            obj.fmlyPk = -1
            obj.mmbrPk = -1
            obj.cellRcvYn = []
            obj.celphn_1 = ''
            obj.celphn_2 = ''
            obj.celphn_3 = ''
            obj.name = ''
            obj.rltns = ''
            obj.msClcd = '0'
            obj.fmlyOrd = Number(max) + 1
            obj.isPay = false
            obj.isBill = false

            this.fmlyInpList.push(obj)
        },

        // 가족 정보 목록 제거,삭제
        rmFmlyRow: function (idx) {
            let obj =  Object.assign(this.fmlyInpList[idx])
            obj.bnMmbrPk = this.bscsInf.bnMmbrPk
            obj.bnfcrPk = this.sltBnfcrPk

            if(obj.mmbrPk == -1)
                this.fmlyInpList.splice(idx, 1)
            else
                this.rmFmlyInf(obj)
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },

        // 주소 검색 내용 적용
        rflAddrInf: function (obj) {
            this.galFmlyInp.zip = obj.zonecode
            this.galFmlyInp.addr = obj.address

            this.rstLclMdlInf()
        },

        // 가족 휴대폰 수신여부 반영
        rflFmlyChc: function (txt, idx) {
            let tmp = Object.assign(this.fmlyInpList[idx])

            switch (txt) {
                case 'pay':
                    tmp.isPay = !tmp.isPay
                    this.$set(this.fmlyInpList, idx, tmp)
                    break;
                case 'bill':
                    tmp.isBill = !tmp.isBill
                    this.$set(this.fmlyInpList, idx, tmp)
                    break;
                default:
                    break;
            }
        },

        // 깊은 복사
        deepCopy: function (array) {
            return array.map(item => 
                Array.isArray(item) ? this.deepCopy(item) : 
                (item && typeof item === 'object') ? {...item} : item
            );
        }
    },

    data: () => ({
        savBtn: true,
        sltBnfcrPk: -1,
        bnfcrPkList: [],
        galFmlyInf: {},
        fmlyList: [],
        fmlyInpList: [],
        galFmlyInp: {
            name: '', rltns: '', msClcd: '0', rsdntBirth: '', isPay: false, isBill: false,
            emailId: '', emailDmn: '', emailDmnClcd: '0',  zip: '', addr: '', addrDtl: '',
            tel_1: '', tel_2: '', tel_3: '', celphn_1: '',  celphn_2: '',  celphn_3: '',
        },
        fmlyInp1 : {
            name: '', rltns: '', msClcd: '0', celphn_1: '',  celphn_2: '',  celphn_3: '', isPay: false, isBill: false,
        },
        fmlyInp2 : {
            name: '', rltns: '', msClcd: '0', celphn_1: '',  celphn_2: '',  celphn_3: '', isPay: false, isBill: false,
        },
        bscsInf: { bnMmbrPk: -1 },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 주소검색)
        billRcvCd: [
            { valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '알림톡' },
            { valcd: '2', valcdnm: '문자' }, { valcd: '3', valcdnm: '이메일' },
        ],
        vCd64: [],
    }),
};
</script>